<template>
	<v-app-bar app>
		<template #prepend>
			<NuxtLink to="/" aria-label="mineclub">
				<v-icon size="48px">
					<v-img src="/img/logo.png" alt="MineClub" height="512" />
				</v-icon>
			</NuxtLink>
		</template>
		<v-toolbar-title><NuxtLink to="/">MineClub</NuxtLink></v-toolbar-title>
		<div class="hidden-xs">
			<v-spacer />
			<NuxtLink to="/"><v-btn aria-label="Hjem">Hjem</v-btn></NuxtLink>
			<NuxtLink to="shop"><v-btn aria-label="Butik">Butik</v-btn></NuxtLink>
			<NuxtLink to="https://discord.mineclub.dk/"><v-btn aria-label="Discord">Discord</v-btn></NuxtLink>
			<NuxtLink v-if="authStore.auth" to="panel"><v-btn :prepend-icon="mdiAccount" aria-label="Panel">Panel</v-btn></NuxtLink>
			<NuxtLink v-else to="login" no-prefetch><v-btn :prepend-icon="mdiAccount" aria-label="Login">Login</v-btn></NuxtLink>
		</div>
		<div class="d-flex d-sm-none">
			<v-menu>
				<template #activator="{ props: props2 }">
					<v-btn color="primary" aria-label="dashboard" v-bind="props2" :icon="mdiMenu"/>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in menuItems" :key="index" :prepend-icon="item.icon">
						<NuxtLink :to="item.to">
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</NuxtLink>
					</v-list-item>
					<v-divider />
					<v-list-item v-if="authStore.auth" :prepend-icon="mdiAccount">
						<NuxtLink to="panel">
							<v-list-item-title>Panel</v-list-item-title>
						</NuxtLink>
					</v-list-item>
					<v-list-item v-else :prepend-icon="mdiAccount">
						<NuxtLink to="login" no-prefetch>
							<v-list-item-title>Login</v-list-item-title>
						</NuxtLink>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
	</v-app-bar>
</template>

<script setup lang="ts">
import { mdiAccount, mdiMenu, mdiHome, mdiStore } from '@mdi/js';
import { computed } from 'vue';
import { useAuthStore } from '~~/stores/auth';

const authStore = useAuthStore();
const props = defineProps({
	items: {
		type: Array,
		default: () => [
			{ title: 'Hjem', to: '/', icon: mdiHome },
			{ title: 'Butik', to: '/shop', icon: mdiStore },
			{ title: 'Discord', to: 'https://discord.mineclub.dk/', icon: "fa:fab fa-discord" },
		],
	},
});

const menuItems = computed(() => props.items) as unknown as { title: string; to: string; icon: string }[];
</script>

<style scoped>
a {
  color: inherit;
  text-decoration: none;
}
</style>